<template>
  <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp"  @init="mescrollInit">
  <div class="page-drive-show bg-main">
    <div class="box-filter">
      <div class="row">
        <div class="item">
          <a-select v-model="curKey"
            placeholder="日期"
             @change="changeCurrent"
            :options="filterOpts"
            />
        </div>
        <div class="item" v-if="role.isSuper">
          <a-select v-model="query.brand_id"
            :placeholder="brandLabel"
            :allowClear="(optList.brandOtps || []).length>1"
            @change="onSearch(1)"
            :options="optList.brandOtps"/>
        </div>
        <div class="item"  v-else-if="role.isDealers">
          <SelectSeller :query.sync="query" placeholder="试驾顾问"/>
        </div>
        <div class="item" v-else-if="role.isVendor && (optList.brandOtps || []).length>1">
          <a-select v-model="query.brand_id"
            :placeholder="brandLabel"
            :allowClear="(optList.brandOtps || []).length>1"
            @change="onSearch(1)"
            :options="optList.brandOtps"/>
        </div>
        <div class="item" v-else>
          <a-select v-model="query.company_type_id"
            placeholder="门店类型"
            :allowClear="(optList.companyTypeOtps || []).length>1"
            @change="handleCompanyTypeChange"
            :options="optList.companyTypeOtps"/>
        </div>
        <div class="item">
          <a-select v-model="query.target_model"
            placeholder="试驾车型"
            @change="onSearch(1)"
            :options="optList.target_model" />
        </div>
        <img src="@/assets/i_filter.png" style="height: 1.2rem; margin-right: 0.5rem;"  @click="popFilter"/>
      </div>
      <div v-if="dateSel" class="custom" style="margin-top: 0.5rem;">
          <div class="date">
          <el-date-picker
            v-model="query.start_date"
            type="date"
            align="center"
            prefix-icon=" "
            @change="changeDate"
            value-format="yyyy-MM-dd"
            placeholder="开始日期">
          </el-date-picker>
          <span>至</span>
          <el-date-picker
            v-model="query.end_date"
            type="date"
            align="center"
            prefix-icon=" "
            @change="changeDate"
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
          </el-date-picker>
          </div>
        </div>
    </div>

    <a-drawer
      title="筛选"
      placement="bottom"
      wrapClassName="pop-filter"
      :closable="true"
      :visible="showFilter"
      :handle="true" 
      height="90%"
      @close="hideFilter"
    >

    <div class="box-search">
    <a-form-model :model="query" layout="vertical"
     :label-col="{span: 9}"
     :wrapper-col="{span: 15}" >
     <div v-if="role.isAdmin">
      <CascadeFilter ref="cFilter"
        :hideDepartment="true"
        :showDealerGroup="true"
        :showDealerCode="true"
        :showComType="true"
        :defaultData="query"
        :defaultList="optList"
        @change="onFilterChange"/>
      
      <a-form-model-item label="人员归属">
        <a-radio-group class="group-btn" button-style="solid" v-model="query.volvo_affiliate">
          <a-radio-button :key="item.value" :value="item.value"
              v-for="item in optList.volvo_affiliate">
              {{item.label}}
            </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="岗位">
        <a-select v-model="query.volvo_role"
          :allowClear="true"
          :options="optList.volvo_role"
          />
      </a-form-model-item>
      </div>

      <a-form-model-item label="试驾顾问">
        <SelectSeller :query.sync="query"/>
      </a-form-model-item>

      <a-form-model-item label="试驾车型">
        <a-select v-model="query.target_model"
          :allowClear="true"
          :options="optList.target_model"
          />
      </a-form-model-item>
      <a-form-model-item label="试驾类型">
        <a-radio-group class="group-btn" button-style="solid" v-model="query.drive_type">
          <a-radio-button :key="item.value" :value="item.value"
              v-for="item in optList.driveOtps">
              {{item.label}}
            </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      

      <a-form-model-item label="线索异常">
        <a-radio-group class="group-btn" button-style="solid" v-model="query.exception">
          <a-radio-button :value="0">全部</a-radio-button>
          <a-radio-button :value="1">正常线索</a-radio-button>
          <a-radio-button :value="2">异常线索</a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item v-show="query.exception==2">
        <a-select
          :allowClear="true"
          v-decorator="[{ initialValue: '0' }]"
          v-model="query.exceptiontype" >
          <a-select-option value="0" >全部</a-select-option>
          <a-select-option value="not_worn" >未佩戴</a-select-option>
          <a-select-option value="not_charge">未充电</a-select-option>
          <a-select-option value="not_uploaded">未上传</a-select-option>
          <a-select-option value="no_cc_card">未绑定铭牌</a-select-option>
          <a-select-option value="hub_not_power">断电（影响数据上传）</a-select-option>
          <a-select-option value="hub_not_net">断网（影响数据上传）</a-select-option>
          <a-select-option value="time_out">录音超长</a-select-option>
<!--          <a-select-option value="passenger_error">客流表迟后</a-select-option>-->
          <a-select-option value="manual_shutdown">铭牌未正常工作</a-select-option>
          <a-select-option value="last_hub_not_power">断电（影响铭牌使用）</a-select-option>
          <a-select-option value="exception">其他情况</a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-input v-model="query.name" placeholder="客户姓名" :allowClear="true" style=""/>
      </a-form-model-item>
      <a-form-model-item>
        <a-input v-model="query.mobile" placeholder="手机号码" :allowClear="true" />
      </a-form-model-item>

      <a-form-model-item>
        <div class="custom">
          <div class="date">
          <el-date-picker
            v-model="query.start_date"
            type="date"
            align="center"
            prefix-icon=" "
            value-format="yyyy-MM-dd"
            placeholder="开始日期">
          </el-date-picker>
          <span>至</span>
          <el-date-picker
            v-model="query.end_date"
            type="date"
            align="center"
            prefix-icon=" "
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
          </el-date-picker>
          </div>
        </div>
      </a-form-model-item>

    </a-form-model>
      <div v-if="showFilter" class="box-btn">
          <span class="reset" @click="onReset">
            重置
          </span>
          <span class="search" @click="onSearch(1)">
            查询
          </span>
        </div>
    </div>
    </a-drawer>

    <div class="box-list">
      <a-table size="default" ref="table" rowKey="id"
        :loading="loading"
        :pagination="false"
        :columns="columns"
        :dataSource="dataList"
        :customRow="clickTableRow"
      @change="handleTableChange">
        <span slot="index" slot-scope="text,record,index">
          {{ index + 1 }}
        </span>


        <template slot="taskErrorTitle">
          <a-tooltip trigger="click">
            铭牌异常
            <template slot="title">
              未充电：客流前一天晚22点至次日凌晨6点没有插在充电坞上<br>
              未佩戴：客流时间插在充电坞上充电<br>
              未上传：客流当天晚22点至24点未插在充电坞上<br>
              录音超时：超过4个小时的录音<br>
              铭牌未正常工作：铭牌监控到关机且当前电量高于30%<br>
              未绑定铭牌：客流对应的用户未绑定铭牌<br>
              其他情况：请联系运维人员
            </template>
            <a-icon type="question-circle" theme="twoTone" />
          </a-tooltip>
        </template>

        <template slot="hubErrorTitle">
          <a-tooltip trigger="click">
            充电坞异常
            <template slot="title">
              断电（影响铭牌使用）: 客流当天凌晨1点至7点出现断电情况<br>
              断网（影响数据上传）: 客流当天23点至次日凌晨1点出现断网情况<br>
              断电（影响数据上传）: 客流当天23点至次日凌晨1点出现断电情况<br>
            </template>
            <a-icon type="question-circle" theme="twoTone" />
          </a-tooltip>
        </template>

        <span slot="task_error" slot-scope="text, record" >
            {{record.task_error.device}}
        </span>

        <span slot="hub_error" slot-scope="text, record" >
            {{record.task_error.hub}}
        </span>

      </a-table>
    </div>
  </div>
    <NavBarDealer v-if="role.isDealer || role.isAfDealerBoth" tab="试驾明细"></NavBarDealer>
    <NavBar v-else tab="试驾明细"></NavBar>
  </mescroll-vue>
</template>

<script>
import Vue from 'vue'
import { postForm, getVolvoAffiliationOtps, getVolvoRoleOtps, getGroupOtpsById, getCompanyOtps, getDepartmentOtpsById, getRegionOtps, getCompanyOtpsById, getBusinessOtps, getEntryStateOtps,gettargetModel} from '@/api'
import {normalDate, toRange} from '@/utils/formatDate'
import pick from 'lodash.pick'
import actions from '@/api/action'
import CascadeFilter from '@/components/CascadeFilter'
import SelectSeller from '@/components/SelectSeller'
import {getRole} from '@/utils/getRole'
import {setDefaultValue} from '@/utils/common'
import NavBar from '@/components/navbarAdmin.vue';
import NavBarDealer from '@/components/navbarDealer.vue';
import MescrollVue from 'mescroll.js/mescroll.vue'
import { getFormatDate } from "@/dbCommon/utils";


export default {
  name: '',
  props: {
  },
  components: {
    MescrollVue,
    NavBar,
    NavBarDealer,
    SelectSeller,
    CascadeFilter
  },
  data() {
    return {
      curKey: "yesterday",
      dateSel: false,
      showFilter: false,
      mescroll: null,
      mescrollDown: {
        use: false,
        auto: false,
      },
      mescrollUp: {
        auto: false,
        dataList: [],
        callback: this.upCallback,
      },
      sellerOtps: [],
      role: null,
      loading: true,
      pageSize: 20,
      total: 10,
      disabled: false,
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      optList: {
      },
      exceptiontype:false,
      query: {
      },
      params: {
      },
      rangeDate: null,
      radioDate: null,
      state: false,
      target_model:[],
      filterOpts: [
        { key: "month", label: "本月" },
        { key: "week", label: "本周" },
        { key: "yesterday", label: "本日" },
        { key: "lastMonth", label: "上月" },
        { key: "custom", label: "自定义" },
      ],
      dataList: [
      ],
      brand_id:''
    }
  },
  computed:{
    brandLabel() {
      return '品牌名称';
    },
    columns() {
      const columns = [
        {
          title: "试驾顾问",
          dataIndex: "seller_name"
        },
        {
          title: "试驾开始时间",
          dataIndex: "seller_time",
          sorter: true,
        },
        {
          title: "试驾结束时间",
          dataIndex: "seller_time_end",
          sorter: true,
        },
        {
          title: '试驾时长',
          dataIndex: "seller_length",
          sorter: true,
        },
        /*
        {
          title: "客户姓名",
          dataIndex: "name"
        },
        */
        {
          title: "客户手机号",
          dataIndex: "mobile"
        },
        {
          title: "试驾车型",
          dataIndex: "target_model"
        },
        {
          title: "试驾类型",
          dataIndex: "drive_type",
          sorter: true,
        },
        {
          title: "评分状态",
          dataIndex: "score_status",
          sorter: true,
        },

        {
          // title: "异常分析",
          dataIndex: "task_error1",
          slots: { title: 'taskErrorTitle'},
          scopedSlots: { customRender: 'task_error' }

        },
        {
          // title: "异常分析",
          dataIndex: "task_error2",
          slots: { title: 'hubErrorTitle'},
          scopedSlots: { customRender: 'hub_error' }

        },
        {
          title: "总分",
          dataIndex: "score",
          sorter: true,
        },
      ]
      if (this.role.isDealer) {
        return columns;
      } else {
        return [
          {
            title: "Dealer Code",
            dataIndex: "code"
          },
          {
            title: "公司名称",
            dataIndex: "store"
          },
          ...columns,
        ];
      }
    }
  },
  methods: {
    handleCompanyTypeChange(v) {
      this.$refs.cFilter.handleCompanyTypeChange(v);
      this.onSearch(1);
    },
    changeDate() {
      this.onSearch(1);
    },
    getDriveType() {
      postForm('/api/option/getDriveTypeList').then(({data}) => {
        if (!data) {
          return;
        }
        const driveOtps = data.map(o => ({key: String(o.id), value: o.id, label: o.name}));
        this.optList = {
          ...this.optList,
          driveOtps
        }
      });
    },
    
    changeCurrent(key) {
      if (key == 'custom') {
        this.dateSel = true;
        this.query = {
          ...this.query,
          start_date: '',
          end_date: '',
        };
        return;
      }
      this.dateSel = false;
      const { start_date, end_date } = getFormatDate(key);
      this.query = {
        ...this.query,
        start_date,
        end_date,
      };
      this.onSearch(1);
    },
    changeData({resetData={}, optList={}}) {
      this.query = {
        ...this.query,
        ...resetData
      }
      this.optList = {
        ...this.optList,
        ...optList
      }
    },
    hideFilter() {
      this.showFilter = false;
      this.query = {...this.tempQ};
      this.optList = {...this.tempL};
    },
    popFilter() {
      this.showFilter = true;
      this.tempQ = {...this.query};
      this.tempL = {...this.optList};
    },
    upCallback (page){
      this.onSearch(page.num + 1);
    },
    mescrollInit (mescroll) {
      this.mescroll = mescroll;
    },
    clickTableRow(record, index) {
      return {
          style: {
            cursor: 'pointer'
          },
          on: {
             click: () => {
               this.$router.push({name: 'receptionShow', query: {id:record.id}});
             }
          }
        }
    },
    
    handleTableChange(pagination, filters, sorter) {
      this.query.field = sorter.field;
      this.query.order = sorter.order;
      this.onSearch(1, this.dataList.length);
    },
    async onFilterChange(query, optList) {
      const {regionOtps, areaOtps, companyOtps} = optList || {};
      this.query = {
        ...this.query,
        ...query,
        brand_id: query.brand_id || undefined,
      }
      var brand_id = query.brand_id
      var company_id = query.company_id
      const res = await actions.getCarModelOtps({brand_id:brand_id,company_id:company_id});
      this.optList = {
        ...this.optList,
        regionOtps: regionOtps || [],
        areaOtps: areaOtps || [],
        companyOtps: companyOtps || [],
        target_model: res,
      }
      
    },
    onReset() {
      this.query = {};
      this.rangeDate=null;
      this.radioDate=null;
      this.$refs.cFilter.reset();
      this.query = {
        ...this.query,
      };
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    onSearch(pageNum, pageSize) {
      this.pageNum = pageNum || this.pageNum;
      if (this.rangeDate) {
        const rd = normalDate(this.rangeDate);
        this.query.start_date = rd[0];
        this.query.end_date = rd[1];
      }
      postForm('/api/drive/lists', {
        ...this.query,
        pageNum: this.pageNum,
        pageSize: pageSize || this.pageSize,
        ...this.$store.state.multi_company_query,
      }).then(res => {
        this.loading = false;
        if (res.status === 1) {
          const list = res.data || [];
          if (this.pageNum == 1) {
            this.dataList = list;
          } else {
            this.dataList = [...this.dataList, ...list];
          }
          this.pageNum = Number(res.pageNum);
          this.total = Number(res.total);
          this.$nextTick(() => {
            this.mescroll.endSuccess(list.length)
          })
          this.showFilter = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    formatOptionList(list) {
      if (!list) {
        return [];
      }
      return list.filter(o => o).map((o, i) => this.formatOption(String(i), o, o));
    },
    formatOption(key, value, label) {
      return {
        key: String(key), value, label, title: label
      }
    },
  },
  created() {
    this.role = getRole();
    const {brand_id:brandId, crop_id_default:cropId} = Vue.ls.get('USER_INFO') || {};
    const {date_type, admin_id, ...rest} = this.$router.currentRoute.query || {};
    const withDate = rest.start_date || rest.end_date;
    this.curKey = date_type || (withDate ? 'custom' : 'yesterday');
    this.query = {
      ...this.query,
      ...rest,
      admin_id: admin_id ? parseInt(admin_id) : undefined,
      crop_id: cropId,
      brand_id: brandId,
      in_num: '首次进店',
    };
    if (this.curKey == 'custom') {
      this.dateSel = true;
    } else {
      this.changeCurrent(this.curKey);
    }
    this.onSearch();
  },
  async mounted () {
    this.optList = {
      ...pick(this.$store.state, [
        'validStateOpts',
      ]),
      brandOtps: await actions.getOptionList('brand', {crop_id: this.query.crop_id}),
      target_model:await actions.getCarModelOtps(),
      volvo_role: await getVolvoRoleOtps(),
      volvo_affiliate: await getVolvoAffiliationOtps(),
      companyTypeOtps: await actions.getCompanyTypeList({crop_id: this.query.crop_id}),
    }
    this.getDriveType()
    
  }
}
</script>
<style scoped lang="less">
/deep/.ant-select {
  width: 100%;
  .ant-select-selection {
    border: 0 !important;
    box-shadow:none !important;
    border-radius: 0 !important;

    .ant-select-selection__rendered {
      margin: 0 0.2rem;
    }
    .ant-select-selection__placeholder {
      text-align: center;
      color: #aaa;
      font-weight: 500;
    }
    .ant-select-selection-selected-value {
      width: 100%;
      padding-right: 12px;
      text-align: center;
      color: #4F5154;
      font-weight: 500;
    }
  }
}

.custom {
  background: #E5E8EE;
  width: 100%;

  /deep/.el-input__inner {
    text-align: center;
    background-color: transparent;
    border: 0;
    padding: 0 0.5rem;
    color: #4F5154;
    height: 2rem;
    line-height: 2rem;
    font-size: 0.7rem;
    font-weight: 500;
    &::-webkit-input-placeholder{
      color: #aaa; 
      font-size: 0.7rem;
      font-weight: 500;
    }
  }
  .date {
    font-size: 0.6rem;
    padding-left: 0.8rem;
    display: flex;
    align-items: center;
    background: url(~@/assets/i_date.png) no-repeat 1rem 0.5rem;
    background-size: 1rem 1rem;
    .el-input__inner {
      padding: 0;
    }
  }
}
.pop-filter {
  /deep/.ant-drawer-content {
    background: #F7FAFD;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;

    .ant-drawer-wrapper-body {
      display: flex;
      flex-direction: column;
    }

    .ant-drawer-body {
      flex: 1;
      padding: 0.75rem;
      overflow-y: auto;
    }

    .ant-form-item {
      padding-bottom: 0;
      margin-bottom: 1rem;
    }
  }
  /deep/.ant-radio-group {
  }
  /deep/.ant-input {
    color: #4F5154;
    font-weight: 500;
    background-color: #E5E8EE;
    border: 0;
    border-radius: 0;
    text-align: center;
    height: 2rem;
    line-height: 2rem;

    &::-webkit-input-placeholder{
      color: #aaa; 
      font-size: 0.7rem;
      font-weight: 500;
    }
  }
  /deep/.ant-select {
    .ant-select-selection {
      background-color: #E5E8EE;
      height: 2rem;
      .ant-select-selection__rendered {
        line-height: 2rem;
      }
    }
  }

  .box-search {
      color: #4F5154;
      font-weight: 500;
      font-size: 0.37333rem;
      padding: 0rem 0 1.5rem 0;
      .item {
        padding: 0 0.5rem;
        margin-bottom: 0.26666rem;
        overflow: hidden;
        border-radius: 2px;

        >.label {
          margin: 0.1rem 0 0.2rem 0;
        }
      }

      .group-btn {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: -0.3rem;

        .ant-radio-button-wrapper {
          box-shadow: none !important;
          margin: 0.3rem 0 0 2%;
          &:nth-child(3n+1) {
            margin-left: 0;
          }
        }
        >label {
          height: 2rem;
          line-height: 2rem;
          border-radius: 2px;
          flex: 1;
          text-align: center;
          background: #E5E8EE;
          border-color: #E5E8EE;
          font-weight: 500;
          min-width: 32%;
          max-width: 32%;
          outline: none;

          &.ant-radio-button-wrapper-checked {
            background: #fff;
            color: #409EFF;
          }
        }
      }

      .box-btn {
        margin-left: -0.75rem;
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 750px;
        display: flex;
        span {
          font-size: 0.8rem;
          height: 2.5rem;
          line-height: 2.5rem;
          flex: 1;
          text-align: center;
          font-weight: 500;
          color: #969799;
          background: #F5F5F5;
          &.search {
            color: #fff;
            background: #377EEA;
          }
        }
      }

  }
}

.page-drive-show {
  padding: 0.75rem;
  min-height: 90vh;

  .box-list {
    overflow: auto;
  }

/deep/.ant-table-thead > tr {
  th {
    border-radius: 0 !important;
    background: rgba(55,126,234,0.1);
    padding: 0.7rem 0.5rem;
    white-space: nowrap;
    border: 0;
    font-size: 0.6rem;
  }
}
/deep/ .ant-table-tbody {
  tr {
    td {
      border-radius: 0 !important;
      padding: 0.5rem;
      white-space: nowrap;
      border: 0;
      font-size: 0.6rem;
    }
  }
  tr:nth-child(odd) {
    background: #fff;
  }
  tr:nth-child(even) {
    background: #F5F5F5;
  }
}


  .box-filter {
    margin-bottom: 1rem;
    align-items: center;
    .row {
      height: 2rem;
      background: #fff;
      border-radius: 2px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .item {
        flex: 1;
        overflow: hidden;
      }
      .item-icon {
        padding-right: 0.5rem;
        font-size: 1rem;
      }
    }
  }
}
</style>
